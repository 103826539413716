<owl-carousel-o id="customer-testi" [options]="customOptions">
    <ng-container *ngFor="let data of customerData">
        <ng-template carouselSlide>
            <div class="row card customer-testi text-center border-0 shadow rounded m-2">
                <div class="card-body">
                    <img src="{{data.image}}" class="img-fluid certifications mx-auto" alt="">      
                </div>
            </div>
        </ng-template>
    </ng-container>
</owl-carousel-o>