import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-index-it-solution',
  templateUrl: './index-it-solution.component.html',
  styleUrls: ['./index-it-solution.component.css']
})

/***
 * It-Solution Component
 */
export class IndexItSolutionComponent implements OnInit {

  /***
   * Nav Bg Class Add
   */
  navClass = 'nav-light';
  
  customerData = [
    {
      image: "https://images.credly.com/size/680x680/images/7a30a2e5-82bb-406b-b0f8-52fd846eac69/cert_mark_SSM_badge_large_300px.png",
      message: "SAFe Scrum Master",
      name: "",
    },
    {
      image: "https://images.credly.com/size/680x680/images/b3a49033-792c-43a8-8d56-bc67f28f85c1/cert_mark_POPM_badge_large_300px.png",
      message: "SAFe Product Owner/Product Manager",
      name: "",
    },
    {
      image: "https://developer.salesforce.com/resources2/certification-site/images/Certifications-logo/Service-Cloud-Consultant.png",
      message: "Salesforce Service Cloud Consultant",
      name: "Dean Tolle",
    },
    {
      image: "https://developer.salesforce.com/resources2/certification-site/images/Certifications-logo/Sales-Cloud-Consultant.png",
      message: "Salesforce Sales Cloud Consultant",
      name: "Jill Webb",
    },
    {
      image: "https://developer.salesforce.com/resources2/certification-site/images/Certifications-logo/Advanced-Administrator.png",
      message: "Salesforce Advanced Administrator",
      name: "",
    },
    {
      image: "https://developer.salesforce.com/resources2/certification-site/images/Certifications-logo/Administrator.png",
      message: "Salesforce Administrator",
      name: "",
    }, 
    {
      image: "https://developer.salesforce.com/resources2/certification-site/images/Certifications-logo/Platform-App-Builder.png",
      message: "Salesforce Platform App Builder",
      name: "",
    }
  ];
  /**
   * Blog Data
   */
  blogData = [
    {
      image: "assets/images/blog/01.jpg",
      title: "Design your apps in your own way",
      like: "33",
      message: "08",
      name: "Calvin Carlo",
      date: "13th August, 2019"
    },
    {
      image: "assets/images/blog/02.jpg",
      title: "How apps is changing the IT world",
      like: "33",
      message: "08",
      name: "Calvin Carlo",
      date: "13th August, 2019"
    },
    {
      image: "assets/images/blog/03.jpg",
      title: "Smartest Applications for Business",
      like: "33",
      message: "08",
      name: "Calvin Carlo",
      date: "13th August, 2019"
    }
  ];

  /**
   * Member Data
   */
  memberData = [
    {
      profile: "assets/images/client/01.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Ronny Jofra",
      designation: "C.E.O"
    },
    {
      profile: "assets/images/client/04.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Micheal Carlo",
      designation: "Director"
    },
    {
      profile: "assets/images/client/02.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Aliana Rosy",
      designation: "Manager"
    },
    {
      profile: "assets/images/client/03.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Sofia Razaq",
      designation: "Developer"
    }
  ];

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }
}
