<!--Engranaje de la izquierda-->
<!--
<app-switcher></app-switcher>
-->
<router-outlet></router-outlet>

<modal-outlet></modal-outlet>

<app-carousel></app-carousel>  

