<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home"
  style="background: url('assets/images/contact-detail.jpg') center center;">
  <div class="bg-overlay bg-overlay-white"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h1 class="title">Contáctanos</h1>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/">Cloud Code</a></li>
                <li class="breadcrumb-item active" aria-current="page">Contáctanos</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->
<section class="section">
  <div class="container">
    <div class="row">
      <!--WhatsApp-->
      <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="card border-0 text-center features feature-clean">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-whatsapp d-block rounded h3 mb-0"></i>
          </div>
          <div class="content mt-3">
            <h5 class="fw-bold">WhatsApp</h5>
            <p class="text-muted">Haznos tus consultas a través de WhatsApp, estaremos felices de apoyarte en lo que
              necesitas.</p>
            <a href="https://wa.me/50422812923" target="_blank" class="video-play-icon text-primary">Contactar</a>
          </div>
        </div>
      </div>

      <!--Telefono-->
      <div class="col-md-4">
        <div class="card border-0 text-center features feature-clean">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-phone d-block rounded h3 mb-0"></i>
          </div>
          <div class="content mt-3">
            <h5 class="fw-bold">Teléfono</h5>
            <p class="text-muted">Llámanos, será un gusto atenderte en nuestra línea telefónica.</p>
            <a href="tel:+50422812923" class="text-primary">+504 2281-2923</a>
          </div>
        </div>
      </div>

      <!--Correo-->
      <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="card border-0 text-center features feature-clean">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-envelope d-block rounded h3 mb-0"></i>
          </div>
          <div class="content mt-3">
            <h5 class="fw-bold">Correo</h5>
            <p class="text-muted">Escríbenos!, te responderemos todas tus dudas o consultas en nuestro buzón de correo.
            </p>
            <a href="mailto:contacto@cloudcode.hn" class="text-primary">contacto@cloudcode.hn</a>
          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
</section>

<!-- Start Contact -->
<section class="section mt-0 pt-0">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card shadow rounded border-0">
          <div class="card-body py-5">
            <h3 class="card-title">Contáctanos</h3>
            <h6 class="card-subtitle">Por favor completa el siguiente formulario, los campos marcados con <span
                class="text-danger">*</span> son obligatorios.</h6>
            <div class="custom-form mt-3 pt-3">
              <form [formGroup]="contactForm" (ngSubmit)="sendMessage()" novalidate>
                <p id="error-msg" class="mb-0"></p>
                <div id="simple-msg"></div>
                <div class="row">
                  <!--Nombre completo-->
                  <div class="col-md-12">
                    <div class="mb-2">
                      <mat-label>Nombre completo<span class="text-danger">*</span></mat-label>
                      <mat-form-field appearance="outline" color="warn">
                        <input matInput placeholder="Ingresa tu nombre completo" type="text" formControlName="name" minlength="10" maxlength="50" required>
                        <mat-icon matPrefix><span class="material-symbols-outlined">person</span></mat-icon>
                        <mat-error *ngIf="contactForm.controls['name'].errors">Nombre completo es obligatorio</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <!--Correo electrónico-->
                  <div class="col-md-12">
                    <div class="mb-2">
                      <mat-label>Correo electrónico<span class="text-danger">*</span></mat-label>
                      <mat-form-field appearance="outline" color="warn">  
                        <input matInput placeholder="Ingresa tu correo electrónico" type="email" formControlName="email" maxlength="100" required>
                        <mat-icon matPrefix><span class="material-symbols-outlined">alternate_email</span></mat-icon>
                        <mat-error *ngIf="!contactForm.controls['email'].valid">Correo electrónico invalido</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <!--Teléfono-->
                  <div class="col-md-12">
                    <div class="mb-2">
                      <mat-label>Teléfono<span class="text-danger">*</span></mat-label>
                      <mat-form-field appearance="outline" color="warn">
                        <input matInput placeholder="Ingresa tu número telefónico" type="tel" formControlName="phone" minlength="7" maxlength="10" required>
                        <mat-icon matPrefix><span class="material-symbols-outlined">phone</span></mat-icon>
                        <mat-error *ngIf="contactForm.controls['phone'].errors">Teléfono invalido</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <!--Mensaje-->
                  <div class="col-md-12">
                    <div class="mb-2">
                      <mat-label>Mensaje<span class="text-danger">*</span></mat-label>
                      <div class="d-flex align-items-start flex-column mb-3">
                        <mat-form-field appearance="outline" color="warn">
                          <textarea matInput placeholder="Detalla tu comentario o consulta" formControlName="message" minlength="10" maxlength="255" cdkTextareaAutosize [cdkAutosizeMinRows]="6" [cdkAutosizeMaxRows]="10" required></textarea>
                            <mat-icon matPrefix><span class="mb-auto p-2 material-symbols-outlined">
                              chat
                              </span></mat-icon>
                            <mat-error *ngIf="contactForm.controls['message'].errors">Debes ingresar un mensaje lo mas detallado posible</mat-error>
                        </mat-form-field>
                      </div>
                      
                    </div>
                  </div>

                </div> 

                <div class="row">
                  <div class="col-12">
                    <mat-progress-bar *ngIf="loading" mode="indeterminate" color="warn"></mat-progress-bar>
                    <div *ngIf="success" class="alert alert-outline-primary alert-pills" role="alert">
                      <span class="alert-content">Muchas gracias por contáctarnos, nuestro equipo se pondrá en contacto contigo a la brevedad.</span>
                   </div>
                    <div class="d-grid mt-3">
                      <button type="submit" [disabled]="!contactForm.valid || loading" class="btn btn-primary">Envíar Mensaje</button>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </form>
            </div>
            <!--end custom-form-->
          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" matTooltip="Ir al principio"
  id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->