<div class="back-to-home rounded d-none d-sm-block">
  <a routerLink="/" class="btn btn-icon btn-soft-primary">
    <i-feather name="home" class="icons"></i-feather>
  </a>
</div>

<!-- ERROR PAGE -->
<section class="bg-home d-flex align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8 col-md-12 text-center">
        <img src="assets/images/nofound.svg" class="img-fluid" alt="">
        <div class="text-capitalize text-dark mb-4 error-page">Página no encontrada</div>
        <p class="text-muted para-desc mx-auto">Estas intentando ingresar a una ruta no disponible dentro del sitio de  <span class="text-primary fw-bold">Cloud Code</span>,
          puedes regresar a la página principal haciendo clic en el siguiente botón.</p>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-md-12 text-center">
        <a routerLink="/" class="btn btn-primary mt-4 ms-2">Ir al inicio</a>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- ERROR PAGE -->