<footer class="footer footer-bar">
    <div class="container text-center">
        <div class="row align-items-center">
            <div class="col-sm-6">
                <div class="text-sm-start">
                    <p class="mb-0">©{{year}} | Todos los derechos reservados <a href="javascript:void(0);"
                            class="text-reset" [ngxScrollTo]="'#home'">
                            <span class="text-primary fw-bold">Cloud Code</span></a>
                    </p>
                </div>
            </div>
            <!--end col-->
            <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">

                <ul class="list-unstyled social-icon social text-sm-end mb-0">
                    <li class="list-inline-item" matTooltip="Twitter"><a href="https://twitter.com/CloudCodeHN"
                            target="_blank" class="rounded">
                            <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                        </a></li>
                    <li class="list-inline-item ms-2" matTooltip="YouTube"><a
                            href="https://www.youtube.com/channel/UC5uoJU_w_xV5zu6gxR7xx6A" target="_blank"
                            class="rounded">
                            <i-feather name="youtube" class="fea icon-sm fea-social"></i-feather>
                        </a></li>
                    <li class="list-inline-item ms-2"><a href="javascript:void(0)" class="rounded">
                            <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                        </a></li>
                    <li class="list-inline-item ms-2"><a href="javascript:void(0)" class="rounded">
                            <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                        </a></li>
                </ul>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</footer>
<!--end footer-->