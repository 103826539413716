<!-- Hero Start -->
<section class="bg-half-170 pb-0 bg-primary d-table w-100" id="home"
  style="background: url('assets/images/bg2.png') center center;">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6">
        <div class="title-heading">
          <h4 class="text-white">Especialistas en la Nube</h4>
          <h4 class="heading text-white mb-3 title-dark"> Somos Cloud Code </h4>
          <!-- <h5 class="text-white">Consultoría | Implementación | Desarrollo | Soporte</h5> -->
          <p class="para-desc text-white">Nuestro compromiso es brindar servicios excepcionales y de calidad, con una
            atención al cliente incomparable, lo que hará que seamos tu mejor aliado.</p>
          <div class="mt-4 pt-2">
            <a href="javascript: void(0);" class="btn btn-light" [ngxScrollTo]="'#services'">Nuestros Servicios</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 col-md-6 mt-5 mt-sm-0">
        <img src="assets/images/hero1.png" class="img-fluid" alt="">
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Partners start -->
<section class="py-4 bg-light">
  <div class="container py-4">
    <app-clients-logo></app-clients-logo>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<section class="section" id="services">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">¿Qué hacemos?</h4>
          <p class="text-muted para-desc mb-0 mx-auto">En <span class="text-primary fw-bold">Cloud Code </span>
            proveemos servicios de Consultoría, Implementación, Desarrollo y Soporte
            para diferentes plataformas de <span class="text-primary fw-bold">Tecnología</span>.
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-bag"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Consultoría</h5>
            <p class="para text-muted mb-0">Nos enfocamos en procesos de negocio y su eficiencia.</p>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-bag"></i>
          </span>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-laptop-cloud"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Implementación</h5>
            <p class="para text-muted mb-0">Buenas prácticas, rápidez y confianza.</p>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-laptop-cloud"></i>
          </span>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-visual-studio"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Desarrollo</h5>
            <p class="para text-muted mb-0">Soluciones tecnológicas hechas a la medida.</p>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-visual-studio"></i>
          </span>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-headphones-alt"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Soporte</h5>
            <p class="para text-muted mb-0">Su mejor aliado para sostener la operatividad de su negocio.</p>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-headphones-alt"></i>
          </span>
        </div>
      </div>
      <!--end col-->



      <div class="col-lg-12 text-center col-md-4 mt-4 pt-2">
        <a href="javascript: void(0);" class="btn btn-primary" [ngxScrollTo]="'#our-story'">Ver más
          <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
        </a>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <!-- About Start -->
  <div class="container mt-100 mt-60" id="our-story">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <div class="row align-items-center">
          <div class="col-lg-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
            <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
              <div class="card-body p-0">
                <img src="assets/images/maintenance.jpg" class="img-fluid" alt="work-image">
                <!--<img src="assets/images/course/online/ab01.jpg" class="img-fluid" alt="work-image">-->
                <div class="overlay-work bg-dark"></div>
                <div class="content">
                  <small class="text-light">Construimos transparencia y </small>
                  <a href="javascript:void(0)" class="title text-white d-block fw-bold">Confianza</a>
                  
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6 col-6">
            <div class="row">
              <div class="col-lg-12 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                  <div class="card-body p-0">
                    <img src="assets/images/busi01.jpg" class="img-fluid" alt="work-image">
                    <!-- <img src="assets/images/course/online/ab02.jpg" class="img-fluid" alt="work-image"> -->
                    <div class="overlay-work bg-dark"></div>
                    <div class="content">
                      <small class="text-light">Nos enfocamos en</small>
                      <a href="javascript:void(0)" class="title text-white d-block fw-bold">Clientes satisfechos</a>
                      
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-lg-12 col-md-12 mt-4 pt-2">
                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                  <div class="card-body p-0">
                    <img src="assets/images/2.jpg" class="img-fluid" alt="work-image">
                    <!-- <img src="assets/images/course/online/ab03.jpg" class="img-fluid" alt="work-image"> -->
                    <div class="overlay-work bg-dark"></div>
                    <div class="content">
                      <small class="text-light">Nos mantenemos en constante</small>
                      <a href="javascript:void(0)" class="title text-white d-block fw-bold">Innovación</a>
                      
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
        <div class="ms-lg-4">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">¿Quiénes somos?</h4>
            <p class="text-muted para-desc">Somos un grupo de <span class="text-primary fw-bold">especialistas</span>
              multidisciplinarios expertos en diferentes ramas de las tecnologías de la información y comunicación.</p>
            <p class="text-muted para-desc mb-0">Con más de 15 años de experiencia en diferentes proyectos en <span
                class="text-primary fw-bold">Latinoamérica</span>
              e industrias, en los cuales hemos puestos en práctica los estándares más altos de calidad e 
              innovación en búsqueda de la satisfacción de nuestros clientes. 
              Nuestros principales valores son:</p>
          </div>

          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Confianza</li>
            <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Clientes Satisfechos</li>
            <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Innovación</li>
            <!-- <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Trabajo en Equipo</li> -->
          </ul>

          <div class="watch-video mt-4 pt-2">
            <a href="javascript: void(0);" class="btn btn-primary m-1 me-2" [ngxScrollTo]="'#how-do-we-works'">Leer más
              <i-feather name="chevron-right" class="fea icon-sm"></i-feather>
            </a>
            <!-- <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
              data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
              <i-feather name="video" class="icons"></i-feather>
            </a><span class="fw-bold text-uppercase small align-middle ms-1">Watch Now</span> -->
            <!-- <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <youtube-player [videoId]="'yba7hPeTSjk'" height="450" width="780">
                </youtube-player>
              </div>
            </ng-template> -->
          </div>
        </div>
      </div>
      <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Nuestras Certificaciones</h4>
              <p class="text-muted para-desc mx-auto mb-0">En <span
                  class="text-primary fw-bold">Cloud Code</span> el aprendizaje es un estilo de vida, es por ello que nos hemos 
                  certificado en diferentes plataformas, metodologías, habilidades y buenas prácticas.</p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <app-customer-testmonial [customerData]="customerData"></app-customer-testmonial>
        <!--end row-->
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->


<!-- End -->

<section class="section mt-0 pt-0" id="how-do-we-works">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h6 class="text-primary">Metodología</h6>
          <h4 class="title mb-4">¿Cómo lo hacemos?</h4>
          <p class="text-muted para-desc mx-auto mb-0">Haciendo uso de <span
              class="text-primary fw-bold">metodologías ágiles</span> logramos incrementar la velocidad del ciclo de vida de los proyectos,
              entregando valor al negocio de forma incremental y temprana.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-md-4 mt-4 pt-2">
        <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-presentation-check d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body">
            <h5 class="text-dark">Planificación y Diseño</h5>
            <p class="text-muted mb-0">Definimos funcionalidades, objetivos, riesgos, plazos de entrega, entre otros.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
        <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-window-section d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body">
            <h5 class="text-dark">Construcción y Desarrollo</h5>
            <p class="text-muted mb-0">Ejecutamos de manera incremental las funcionalidades definidas, apegados a las mejores prácticas.</p>
          </div>
        </div>
      </div>
      <!--end col-->
      

      <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
        <div class="card features feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-file-check-alt d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body">
            <h5 class="text-dark">Revisión y Retroalimentación</h5>
            <p class="text-muted mb-0">Validamos a través de las pruebas el correcto funcionamiento y obtenemos la opinión de los usuarios de manera temprana.</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  
  <!--end container-->
</section>
<!--end section-->



<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" matTooltip="Ir al principio" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->