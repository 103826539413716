import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-page-contact-three',
  templateUrl: './page-contact-three.component.html',
  styleUrls: ['./page-contact-three.component.css']
})

/**
 * Page Contact-Three Component
 */
export class PageContactThreeComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private modalService: NgbModal) { }

  loading: boolean = false;
  success: boolean = false;
  contactForm = this.formBuilder.group({
    name: ['', Validators.required],
    email: ['', Validators.email],
    phone: ['', Validators.required],
    message: ['', Validators.required]
  });

  
  ngOnInit(): void {
  }

  mapView(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true })
  }

  sendMessage() {
    this.loading = true;
    console.log('Formulario de contacto ', this.contactForm);
    setTimeout(() => {
      this.loading = false;
      this.success = true;
      this.contactForm.reset();
    }, 5000);

    setTimeout(() => {
      this.success = false;
    }, 10000);
  }

}
